<template>
  <div class="app-container">
    <el-row :gutter="15">
      <el-col :span="4">
        <el-select
          style="width: 100%; margin-bottom: 10px"
          @change="handleFilter"
          v-model="listQuery.sourceApp"
          filterable
          clearable
          :placeholder="$t('commons.appName')"
        >
          <el-option
            v-for="item in appList"
            :key="item.itemCode"
            :value="item.itemCode"
            :label="item.itemName"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%; margin-bottom: 10px"
          v-model="moduleList"
          multiple
          collapse-tags
          filterable
          :placeholder="$t('auditLog.module')"
          @change="findByModuleList"
        >
          <el-option
            v-for="item in this.moduleOptions"
            :key="item"
            :value="item"
            :label="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.userAccount')"
          maxlength="50"
          v-model="listQuery.userAccount"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
        />
      </el-col>
      <el-col :span="4"
        ><el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.ip')"
          maxlength="50"
          v-model="listQuery.ip"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
      /></el-col>
      <el-col :span="4"
        ><el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.host')"
          maxlength="50"
          v-model="listQuery.host"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
      /></el-col>
      <el-col :span="4"
        ><el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.description')"
          maxlength="50"
          v-model="listQuery.message"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
      /></el-col>
      <el-col :span="4"
        ><el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.operation')"
          maxlength="50"
          v-model="listQuery.operation"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
      /></el-col>
      <el-col :span="4"
        ><el-input
          style="width: 100%; margin-bottom: 10px"
          :placeholder="$t('auditLog.source')"
          maxlength="50"
          v-model="listQuery.source"
          clearable
          class="filter-item"
          @keyup.enter.native="handleFilter"
      /></el-col>
      <el-col :span="8"
        ><el-date-picker
          style="width: 100%; margin-bottom: 10px"
          class="filter-item"
          v-model="pickerOpts.pickerData"
          type="datetimerange"
          :picker-options="pickerOpts"
          @change="setDateRange"
          :range-separator="$t('commons.rangeSeparator')"
          :start-placeholder="$t('commons.startDate')"
          :end-placeholder="$t('commons.endDate')"
        >
        </el-date-picker
      ></el-col>
      <el-col :span="4"
        ><tenant-select
          style="width: 100%; margin-bottom: 10px"
          size="small"
          class="filter-item"
          v-model="listQuery.tenantId"
          @change="handleFilter"
        ></tenant-select
      ></el-col>
      <el-col :span="4"
        ><el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          :loading="listLoading"
          @click="handleFilter"
          >{{ $t("commons.search") }}
        </el-button></el-col
      >
    </el-row>
    <el-table
      v-loading="listLoading"
      :data="list"
      @sort-change="handleSort"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :resizable="false"
        :label="$t('commons.index')"
        width="80"
      >
      </el-table-column>
      <el-table-column prop="tenantName" :label="$t('tenant.tenantName')">
        <template slot-scope="scope">
          <span>{{ scope.row.tenantName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="creationDate"
        sortable="custom"
        :label="$t('auditLog.creationDate')"
        width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>
            {{
              scope.row.operationTimestamp | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="userAccount"
        sortable="custom"
        :label="$t('auditLog.userAccount')"
        width="110"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ip"
        sortable="custom"
        :label="$t('auditLog.ip')"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="host"
        sortable="custom"
        :label="$t('auditLog.host')"
        width="220"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="operation"
        sortable="custom"
        :label="$t('auditLog.operation')"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="module"
        sortable="custom"
        :label="$t('auditLog.module')"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sourceApp"
        sortable="custom"
        :label="$t('commons.appName')"
        width="130"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="message" :label="$t('auditLog.description')">
      </el-table-column>
      <el-table-column
        prop="source"
        sortable="custom"
        :label="$t('auditLog.source')"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="operator" :label="$t('auditLog.operation')">
        <template slot-scope="scope">
          <span @click="lineDetailView(scope.row)" class="view-span"
            >查看详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.current"
      :limit.sync="listQuery.rowCount"
      @pagination="getList"
    />
    <el-dialog
      title="日志详情"
      :visible.sync="logDetail.dialogShow"
      @close="
        () => {
          logDetail.dialogShow;
        }
      "
      :close-on-click-modal="false"
    >
      <LogDetail :tableDatas="logDetail.tableDatas" />
    </el-dialog>
  </div>
</template>

<script>
import { getList, findAppList, getModelList } from "@/api/ruge/logger/auditLog";
import Pagination from "@/components/Pagination";
import { buildSortStr } from "@/utils/global";
import TenantSelect from "@/components/TenantSelect";
import LogDetail from "./logDetail.vue";
export default {
  name: "AuditList",
  components: { Pagination, TenantSelect, LogDetail },
  data() {
    let opt = {
      moduleOptions: [],
      moduleList: null,
      appList: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        current: 1,
        rowCount: 10,
        // 排序条件,格式：column1:asc;column2:desc
        sortStr: null,
        module: null,
        sourceApp: null,
        operation: null,
        tenantId: null,
        startDate: null,
        endDate: null,
        userAccount: null,
        ip: null,
        host: null,
        message: null,
        source: null,
      },
      // 日期范围查询组件
      pickerOpts: {
        // 默认日期
        pickerData: null,
        // 快捷选项
        shortcuts: null,
      },
      logDetail: {
        dialogShow: false,
        defaultData: [],
        tableDatas: [],
      },
    };
    // 设置Picker快捷选项
    this.setPickerShortcuts(opt);
    return opt;
  },
  created() {
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("logger_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.listQuery = { ...this.listQuery, ...tempObj };
        if (tempObj.startDate != null && tempObj.endDate != null) {
          this.pickerOpts.pickerData = [tempObj.startDate, tempObj.endDate];
        }
      }
    }
    this.getList();
    this.getAppList();
    this.getModelList();
  },
  watch: {
    // 切换语言重新设置快捷选项
    language: function () {
      this.setPickerShortcuts();
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    getModelList() {
      getModelList().then((r) => {
        this.moduleOptions = r;
      });
    },
    findByModuleList() {
      if (this.moduleList.length < 1) {
        this.listQuery.module = null;
        this.handleFilter();
      } else {
        this.listQuery.module = this.moduleList.join(",");
        this.handleFilter();
      }
    },
    lineDetailView(row) {
      this.$router.push({
        path: "/logger/logger/detail",
        query: {
          logUUID: row.logUUID,
        },
      });
      sessionStorage.setItem("logger-detail-datas", JSON.stringify(row));
    },
    getAppList() {
      findAppList({ classifyCode: "APP_LIST" }).then((r) => {
        this.appList = r;
      });
    },
    getList() {
      if (this.listQuery.tenantId == null) {
        this.listQuery.tenantId = -1;
      }
      this.listLoading = true;
      const param = this.listQuery;
      sessionStorage.setItem("logger_list_query_params", JSON.stringify(param));
      getList(this.listQuery).then((response) => {
        this.list = response.rows;
        this.total = response.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.current = 1;
      this.getList();
    },
    // 处理动态排序
    handleSort(column) {
      this.listQuery.sortStr = buildSortStr(column);
      this.getList();
    },
    // 设置日期范围查询条件
    setDateRange(dateRange) {
      if (dateRange) {
        this.listQuery.startDate = dateRange[0].getTime();
        this.listQuery.endDate = dateRange[1].getTime();
      } else {
        // 清空
        this.listQuery.startDate = null;
        this.listQuery.endDate = null;
      }
    },
    // 设置Picker快捷选项
    setPickerShortcuts(opt = this) {
      opt.pickerOpts.shortcuts = [
        {
          text: this.$t("commons.lastDay"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastWeek"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("commons.lastMonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.view-span {
  cursor: pointer;
  color: #1a4cec;
}
</style>